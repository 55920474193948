<template>
  <div id="div-home">
    <div class="row feed boxw mb-5">
      <div class="box pb-3 pr">
        <button v-if="$route.params.user === post.username" @click="editPostFN" class="btn btn-pure closeModal"><i class="fas fa-edit"></i></button>
        <div class="user_info mt-3 mb-1">
          <div class="profile-image">
            <router-link :to="'/profile/' + $route.params.id + '/' + $route.params.user"><img v-lazy="'/users/' + Math.floor($route.params.id / 1000) + '/' + $route.params.user + '/imgp.jpg'"></router-link>
          </div>
          <div><i class="fas fa-certificate badge" style="color:rgb(30,202,255)"><i class="fas fa-check"></i></i> <router-link :to="'/profile/' + $route.params.id + '/' + $route.params.user">{{ $route.params.user }}</router-link><br /><span class="data" v-data1>{{post.updated_at}}</span></div>
        </div>
        <div class="pd-t-15 pd-l-15 pd-r-15 pd-b-0">
          <textarea id="post-edit124" v-if="editPost" class="form-control h-150" v-model="post.description1"></textarea>
          <p v-else class="m-0" v-html="post.description"></p>
        </div>
        <p v-if="editPost" class="m-15 m-b-0 text-center"><a class="button button2" @click="upPost">Atualizar</a></p>
        <div class="feed-img"><a class="mao" @dblclick="clickImg"><i class="fa">&#x1F525;</i><img :src="'/' + post.image" /></a></div>
        <div class="pl-2 ml-2"><a :class="{'cursor-inherit': !(post.likes > 0)}" @click="viewLikes" ><i class="fas fa-fire"></i> {{ post.likes || 0 }} Likes</a> <a :class="{'cursor-inherit': post.comments == comentarios.length}" @click="viewComments"><i class="far fa-comment ml-2"></i> {{ post.comments || 0 }} Comentarios</a></div>
        <div class="user_info">
          <div class="profile-image" style="display:none">
            <img v-lazy="'/users/' + Math.floor(post.user_id / 1000) + '/' + post.username + '/imgp.jpg'">
          </div>
          <div class="comentario ml-3 mr-3 pr m-t-15">
            <input type="text" class="input-comentario" v-model="comentario" placeholder="Escreva seu comentário" @keyup.enter="sendComment">
            <button :disabled="!comentario.length" @click="sendComment" class="btn fas fa-paper-plane btn-comentario "></button>
          </div>
        </div>
        <div v-if="comentarios[0]" class="m-t-20">
          <div class="user_info mt-2 mb-2" v-for="(dt, index) in comentarios" :key="index">
            <div class="profile-image" v-show="!dt.show">
              <router-link :to="'/profile/' + dt.user_id + '/' + dt.username"><img :src="'/users/' + Math.floor(dt.user_id / 1000) + '/' + dt.username + '/imgp.jpg'"></router-link>
              <p class="m-b-0 m-t-10" v-if="dt.username === user.username">
                <a @click="editComentarioShow(dt, index)"><i class="fas fa-edit"></i></a>
                <a class="m-l-10 text-danger" @click="delComment(dt.id, index)"><i class="fas fa-trash"></i></a>
              </p>
            </div>
            <div class="item-list" v-show="!dt.show">
              <div class="subcomentario">
                <router-link :to="'/profile/' + dt.user_id + '/' + dt.username">{{ dt.username }}</router-link>
                <span class="m-l-5 v-data1">{{ dt.updated_at }}</span>
                <p :class="{mao: dt.username === user.username}" @click="editComentarioShow(dt, index)"> {{dt.comment}}</p>
              </div>
              <a @click="likeComment(dt)"><i class="fas fa-fire"></i> {{ dt.likes || 0 }} Likes</a>
            </div>
            <div v-if="dt.show" class="comentario ml-3 mr-3 pr m-t-15 m-b-15">
              <input :id="'comentario-' + index" type="text" class="input-comentario" v-model="dt.comment1" placeholder="Escreva seu comentário" @keyup.enter="editComment(dt, index)" @keyup.esc="dt.show = 0">
              <button :disabled="!dt.comment1.length" @click="editComment(dt, index)" class="btn fas fa-paper-plane btn-comentario"></button>
            </div>
          </div>
        </div>
        <p class="m-15 m-b-0 text-center"><a class="button button12" @click="viewComments" v-if="post.comments > comentarios.length">Ver comentarios</a></p>
      </div>

    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { post, postsComments, postsLikes, comments, commentsDelete, commentsEdit, like, likeDelete, postsEdit } from './../resources/fn'
import { error } from '../resources/functions'

export default {
  name: 'post',
  data () {
    return {
      post: {},
      comentarios: [],
      likes: [],
      comentario: '',
      editPost: false
    }
  },
  beforeMount () {
    this.SET_LOAD(true)
    post(this.axios, this.$route.params.id, this.$route.params.user, this.$route.params.id_post).then((res) => {
      this.post = res.data
      this.SET_LOAD()
    }, (res) => {
      error(this, this.$t('post.erro_carregar_post'))
      this.SET_LOAD()
    })
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_MODAL_LIKES',
      'SET_LIKES'
    ]),
    viewComments () {
      if (this.post) {
        if (this.post.comments > 0 && this.post.comments > this.comentarios.length) {
          this.SET_LOAD(true)
          postsComments(this.axios, this.$route.params.id, this.$route.params.user, this.$route.params.id_post, this.comentarios.length > 0 ? this.comentarios[this.comentarios.length - 1].id : '').then((res) => {
            this.comentarios = this.comentarios.concat(res.data)
            this.SET_LOAD()
          }, () => {
            error(this, this.$t('post.erro_apresentar_comentarios'))
            this.SET_LOAD()
          })
        }
      } else {
        this._.delay(self => {
          this.viewComments()
        }, 100, this)
      }
    },
    editComentarioShow (dt, index) {
      if (dt.username === this.user.username) {
        dt.comment1 = dt.comment
        this.comentarios = this.comentarios.map(a => {
          a.show = 0
          return a
        })
        dt.show = 1
        window.setTimeout((ind) => {
          document.getElementById('comentario-' + ind).focus()
        }, 300, index)
      }
    },
    sendComment () {
      if (this.comentario.length) {
        this.SET_LOAD(true)
        comments(this.axios, this.$route.params.id, this.$route.params.user, { post_id: this.$route.params.id_post, comment: this.comentario }).then((res) => {
          this.comentarios.unshift(res.data)
          this.SET_LOAD()
          this.comentario = ''
          ++this.post.comments
        }, (res) => {
          error(this, this.$t('post.erro_a_comentar'))
          this.SET_LOAD()
        })
      } else {
        error(this, this.$t('post.erro_comentario_nao_pode_ser_vazio'))
      }
    },
    delComment (id, index) {
      this.SET_LOAD(true)
      commentsDelete(this.axios, { id: id }).then((res) => {
        this.comentarios.splice(index, 1)
        --this.post.comments
        this.SET_LOAD()
      }, (res) => {
        error(this, this.$t('post.erro_nao_foi_possivel_apagar_comentario'))
        this.SET_LOAD()
      })
    },
    editComment (dt, index) {
      if (dt.comment1.length) {
        this.SET_LOAD(true)
        commentsEdit(this.axios, { id: dt.id, comment: dt.comment1 }).then((res) => {
          this.comentarios.splice(index, 1)
          this.comentarios.unshift(res.data)
          this.SET_LOAD()
        }, (res) => {
          error(this, this.$t('post.erro_nao_foi_possivel_editar_comentario'))
          this.SET_LOAD()
        })
      } else {
        error(this, this.$t('post.erro_comentario_nao_pode_ser_vazio'))
      }
    },
    viewLikes () {
      if (this.post.likes > 0) {
        this.SET_LOAD(true)
        postsLikes(this.axios, this.$route.params.id, this.$route.params.user, this.$route.params.id_post).then((res) => {
          this.likes = res.data
          this.SET_MODAL_LIKES(1)
          this.SET_LIKES(this.likes)
          this.SET_LOAD()
        }, () => {
          error(this, this.$t('post.erro_nao_foi_possivel_apressentar_os_likes'))
          this.SET_LOAD()
        })
      }
    },
    // clica numa imagem e ativa um icone por cima
    clickImg (e) {
      this.SET_LOAD(true)
      if (!this.post.is_liked) {
        like(this.axios, this.$route.params.id, this.$route.params.user, { content_id: this.$route.params.id_post, type: 'post' }).then((res) => {
          e.target.parentElement.className = 'mao clickImg'
          ++this.post.likes
          this.SET_LOAD()
          window.setTimeout(e => {
            e.target.parentElement.className = 'mao'
          }, 1500, e)
          this.post.is_liked = true
        }, (res) => {
          error(this, this.$t('post.erro_dar_like'))
        })
      } else {
        likeDelete(this.axios, this.$route.params.id, this.$route.params.user, { content_id: this.$route.params.id_post, type: 'post' }).then((res) => {
          e.target.parentElement.className = 'mao clickImg'
          --this.post.likes
          this.SET_LOAD()
          window.setTimeout(e => {
            e.target.parentElement.className = 'mao'
          }, 1500, e)
          this.post.is_liked = false
        }, (res) => {
          error(this, this.$t('post.erro_retirar_like'))
        })
      }
    },
    likeComment (dt) {
      this.SET_LOAD(true)
      if (!dt.like) {
        like(this.axios, this.$route.params.id, this.$route.params.user, { content_id: this.$route.params.id_post, type: 'comment' }).then((res) => {
          ++dt.likes
          dt.like = true
          this.SET_LOAD()
        }, (res) => {
          error(this, this.$t('post.erro_dar_like_comentario'))
          this.SET_LOAD()
        })
      } else {
        likeDelete(this.axios, this.$route.params.id, this.$route.params.user, { content_id: this.$route.params.id_post, type: 'comment' }).then((res) => {
          --dt.likes
          dt.like = false
          this.SET_LOAD()
        }, (res) => {
          error(this, this.$t('post.erro_retirar_like_comentario'))
          this.SET_LOAD()
        })
      }
    },
    editPostFN (dt, index) {
      this.editPost = true
      this.post.description1 = this.post.description
      window.setTimeout(() => {
        document.getElementById('post-edit124').focus()
      }, 300)
    },
    upPost () {
      this.SET_LOAD(true)
      postsEdit(this.axios, { id: this.post.id, description: this.post.description1 }).then((res) => {
        this.post = res.data
        this.editPost = false
        this.SET_LOAD()
      }, (res) => {
        error(this, this.$t('post.erro_nao_foi_possivel_tirar_like_comentario'))
        this.SET_LOAD()
      })
    }
  }
}
</script>
